import 'alpine-magic-helpers'; //@Alpine Extensionr: https://www.alpinetoolbox.com/extensions/
import Alpine from 'alpinejs';
import L from 'leaflet';
import 'leaflet.markercluster';

import { Transitions } from '../transitions';

import {
  Solar,
} from '../solar';
import {
  Carousel,
  Store,
  Menu
} from '../util';

const preloader = document.querySelector('.preloader');

export const Common = {
  init() {
    window.Alpine = Alpine; //Initializing Alpine.js
    window.L = L; //Initializing Leaflet
    window.L.markerClusterGroup = L.markerClusterGroup; //Initializing the Leaflet Marker Cluster
    Alpine.start(); //Starting Alpine
    Menu.init(); //Starting the Menu Context
    Store.setup(); //Starting the Store
    Solar.start(); //Starting the Solar Framework
    Carousel.init(); //Starting the Carousel
    preloader.classList.remove('is-loading'); //Removing the loading class.
  },
  finalize() {
    window.useInertia.before(() => {
    })
    window.useInertia.after(() => {
      Carousel.init(); //Re-starting the carousel on transition end. 
    })
    window.inertia.setup.transitions = Transitions; //Setting up the transitions. 
    window.inertia.init(window.inertia.setup);
  },
};
